import axios from "axios";



const { VUE_APP_SHIELD_URL } = process.env;

export default class pdfService {

    // static async makePdf(poliza) {
    //     try {
    //         //Llamada al controlador pdfMake de Vaulperk
    //         const PdfRes = await axios.post(
    //             `${VUE_APP_NOTE_URL}note/api/maketoPdf`,
    //             {
    //                 numeroPoliza: poliza,
    //             },
    //             {
    //                 mode: 'cors',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Access-Control-Allow-Origin': '*',
    //                 }
    //             }
    //         )

    //         if (PdfRes.data.data == 'Aw==') {
    //             throw new Error("Pdf deberia estar en Aws");
    //         }

    //         let fileFinal = "data:application/pdf;base64," + PdfRes.data.data;
    //         //Crear PDF
    //         let finalObject = {
    //             status: PdfRes.data.status,
    //             fileFinal: fileFinal
    //         }
    //         return finalObject;

    //         // let decodedBase64 = base64.base64Decode(PdfRes.data.data, 'public/PolizaGenerada.pdf');
    //         // console.log(decodedBase64)
    //     } catch (e) {
    //         console.log(e.message);
    //         return {
    //             status: "error",
    //             message: e.message,
    //         };
    //     }
    // }

    static async pdfAwsBucket(poliza) {
        try 
            {       
            // Llamada al controlador shield
            const PdfRes = await axios.get(
                `${VUE_APP_SHIELD_URL}/cartSoap/getAmazoniaBucket/${poliza}`,
                {
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    }
                }

            )

            let fileFinal = "data:application/pdf;base64,"+PdfRes.data.data;
            let finalObject = {
                status: PdfRes.data.status,
                fileFinal: fileFinal
            }

            return finalObject;
            } catch (error) {
                console.log(error.message);
                return {
                    status: "error",
                    message: "Ocurrio un error al obtener encontrar el Id",
                };
            }
        }

    static async pdfAws(poliza, sponsor, redirect, token) {
        try {
            //Llamada al controlador pdfMake de Vaulperk
            const PdfRes = await axios.get(
                `${VUE_APP_SHIELD_URL}/car/getAmazonia/${poliza}/${sponsor}/${redirect}`,
                {
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        "x-captcha-authorization": token,
                    }
                }

            )

            console.log(PdfRes.data.data);

            let fileFinal = "data:application/pdf;base64," + PdfRes.data.data.data;
            //Crear PDF
            let finalObject = {
                status: PdfRes.data.status,
                fileFinal: fileFinal
            }
            // let decodedBase64 = base64.base64Decode(PdfRes.data.data, 'public/PolizaGenerada.pdf');

            // console.log(decodedBase64)

            return finalObject;
        } catch (error) {
            console.log(error.message);
            return {
                status: "error",
                message: "Ocurrio un error al obtener encontrar el Id",
            };
        }
    }
}
